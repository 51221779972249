<template>
  <div class="d-flex flex-row justify-content-end align-items-center">
    <b-button
      v-if="expand"
      variant="flat-primary"
      class="btn-icon rounded-circle"
      size="sm"
      @click="$emit('expand')"
    >
      <feather-icon
        size="15"
        :icon="expanded ? 'ChevronUpIcon' : 'ChevronDownIcon'"
      />
    </b-button>
    <slot name="quickAction" />
    <b-dropdown
      size="sm"
      variant="flat-secondary"
      toggle-class="text-decoration-none btn-icon rounded-circle ml-1"
      no-caret
      boundary="viewport"
    >
      <template #button-content>
        <feather-icon
          size="15"
          icon="MoreVerticalIcon"
        />
      </template>
      <slot name="custom" />
      <b-dropdown-item
        v-if="editable"
        @click="$emit('edit')"
      >
        <feather-icon
          class="mr-50"
          icon="EditIcon"
        />
        <span>Редактировать</span>
      </b-dropdown-item>
      <b-dropdown-divider v-if="deletable" />
      <b-dropdown-item
        v-if="deletable"
        @click="$emit('delete')"
      >
        <feather-icon
          class="text-danger mr-50"
          icon="Trash2Icon"
        />
        <span class="text-danger">Удалить</span>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import {
  BButton, BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'

export default {
  name: 'RowActions',
  components: {
    BButton, BDropdown, BDropdownItem, BDropdownDivider,
  },
  props: {
    more: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    deletable: {
      type: Boolean,
      default: false,
    },
    expand: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>

</style>
