<template>
  <b-card class="card card-congratulations cursor-pointer mb-0 full-height">
    <div class="d-flex align-items-center">
      <div>
        <b-card-title class="text-white">
          {{ hometask.name }}
        </b-card-title>

        <div class="d-flex flex-column">
          <span
            v-if="student"
            class="font-small-2"
          >Учитель: {{ fullTeacherName }}</span>
          <span
            v-else
            class="font-small-2"
          >Ученик: {{ fullStudentName }}</span>
          <span class="font-small-2">Назначено {{ hometask.updated | dateTime }}</span>
        </div>
      </div>
      <div class="flex-fill" />
      <b-button
        class="btn-icon"
        variant="primary"
        title="Перейти"
        :to="{name: 'cabinet.hometasks.task', params: {task_id: hometask.id}}"
      >
        <feather-icon
          size="25"
          icon="ExternalLinkIcon"
        />
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardTitle, BButton } from 'bootstrap-vue'
import { getUserFullName } from '../../helpers'

export default {
  name: 'ActiveHometaskCard',
  components: {
    BCard, BCardTitle, BButton,
  },
  props: {
    hometask: {
      type: Object,
      required: true,
    },
    student: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fullTeacherName() {
      return getUserFullName(this.hometask.teacher)
    },
    fullStudentName() {
      return getUserFullName(this.hometask.student)
    },
  },
}
</script>

<style scoped>

</style>
