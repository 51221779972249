import { render, staticRenderFns } from "./Hometasks.vue?vue&type=template&id=519f6866&scoped=true&"
import script from "./Hometasks.vue?vue&type=script&lang=js&"
export * from "./Hometasks.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "519f6866",
  null
  
)

export default component.exports