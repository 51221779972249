<template>
  <div v-if="hometasks.length || loading">
    <h2 class="mb-1">
      Активные занятия
    </h2>
    <b-row v-if="hometasks.length && !loading">
      <b-col
        v-for="task in hometasks"
        :key="task.id"
        cols="12"
        md="6"
        xl="4"
        class="mb-2"
      >
        <active-hometask-card
          :student="student"
          :hometask="task"
        />
      </b-col>
    </b-row>
    <b-row v-else-if="loading">
      <b-col
        v-for="skel in 3"
        :key="`skel-${skel}`"
        cols="12"
        md="6"
        xl="4"
      >
        <session-online-skeleton />
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <div class="d-flex justify-content-center">
          <h3 class="text-center">
            Пока нет домашних занятий для выполнения
          </h3>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import ActiveHometaskCard from './ActiveHometaskCard.vue'
import SessionOnlineSkeleton from '../session/student/SessionOnlineSkeleton.vue'
import { HOMETASK_STATUS_ACTIVE } from '../../shared/constants'

export default {
  name: 'ActiveHometasksList',
  components: {
    SessionOnlineSkeleton, ActiveHometaskCard, BRow, BCol,
  },
  props: {
    student: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    hometasks: [],
    loading: false,
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
  },
  created() {
    this.getHometasks()
  },
  methods: {
    async getHometasks() {
      this.loading = true

      const params = {
        status: HOMETASK_STATUS_ACTIVE,
        expand: 'student.usersStudent, teacher.usersTeachers',
      }

      if (!this.student) {
        params.teacher_id = this.user.id
      } else {
        params.student_id = this.user.id
      }

      const { items } = await this.$http.get('/home-tasks', {
        params,
      })
      this.hometasks = items
      this.loading = false
    },
  },
}
</script>

<style scoped>

</style>
