var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('active-hometasks-list',{attrs:{"student":!_vm.isTeacher}}),_c('b-row',[_c('b-col',[_c('div',{staticClass:"mt-2 mb-1 d-flex align-items-center"},[_c('h2',[_vm._v(" Неактивные занятия ")]),_c('div',{staticClass:"flex-fill"}),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.createHometask}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_vm._v(" Создать ")],1)],1),_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('table-header',{attrs:{"search":"","filter":_vm.filter,"search-updated":_vm.getHometasks,"students":_vm.students,"statuses":_vm.statuses,"statuses-dropdown":""}}),_c('b-table',{ref:"teachersTable",attrs:{"striped":"","items":_vm.hometasks,"responsive":"","fields":_vm.tableFields,"primary-key":"id","show-empty":"","empty-text":_vm.$t('page.students.search.labels.no-items'),"sort-by":_vm.filter.sortBy,"sort-desc":_vm.filter.sortDesc,"hover":""},on:{"update:sortBy":function($event){return _vm.$set(_vm.filter, "sortBy", $event)},"update:sort-by":function($event){return _vm.$set(_vm.filter, "sortBy", $event)},"update:sortDesc":function($event){return _vm.$set(_vm.filter, "sortDesc", $event)},"update:sort-desc":function($event){return _vm.$set(_vm.filter, "sortDesc", $event)},"row-clicked":_vm.showMore},scopedSlots:_vm._u([{key:"cell(student_name)",fn:function(ref){
var item = ref.item;
var value = ref.value;
return [_c('div',{staticClass:"d-flex align-items-center"},[(item.student)?_c('store-image',{staticClass:"mr-50",attrs:{"store-id":item.student.image_id,"avatar":"","size":"20"}}):_vm._e(),_c('span',[_vm._v(_vm._s(value))])],1)]}},{key:"cell(status)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{attrs:{"pill":"","variant":_vm.statusColor(item.status)}},[_c('span',[_vm._v(_vm._s(_vm.statusLabel(item.status)))])])]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('row-actions',{attrs:{"editable":_vm.isTeacher,"deletable":_vm.isTeacher},on:{"edit":function($event){return _vm.$router.push({name: 'cabinet.hometasks.task', params: {task_id: item.id}})},"delete":function($event){return _vm.deleteHometask(item)}},scopedSlots:_vm._u([{key:"quickAction",fn:function(){return [_c('b-button',{staticClass:"btn-icon ml-50 rounded-circle",attrs:{"variant":"flat-primary","size":"sm","to":{name: 'cabinet.hometasks.task', params: {task_id: item.id}}}},[_c('feather-icon',{attrs:{"size":"15","icon":_vm.isTeacher ? 'Edit3Icon' : 'ExternalLinkIcon'}})],1)]},proxy:true},{key:"custom",fn:function(){return [_c('b-dropdown-item',{on:{"click":function($event){return _vm.$router.push({name: 'cabinet.hometasks.task', params: {task_id: item.id}})}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"ExternalLinkIcon"}}),_c('span',[_vm._v("Открыть")])],1),(_vm.isTeacher)?_c('b-dropdown-divider'):_vm._e(),(_vm.isTeacher && item.status === _vm.doneStatus)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.updateTaskStatus(item, _vm.activeStatus)}}},[_c('feather-icon',{staticClass:"text-warning mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"text-warning"},[_vm._v(_vm._s(_vm.activeStatusLabel))])],1):(_vm.isTeacher)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.updateTaskStatus(item, _vm.doneStatus)}}},[_c('feather-icon',{staticClass:"text-success mr-50",attrs:{"icon":"CheckIcon"}}),_c('span',{staticClass:"text-success"},[_vm._v(_vm._s(_vm.doneStatusLabel))])],1):_vm._e()]},proxy:true}],null,true)})]}},{key:"row-details",fn:function(ref){
var toggleDetails = ref.toggleDetails;
var item = ref.item;
return [_c('hometask-info',{attrs:{"task":item},on:{"hide":toggleDetails}})]}}])}),_c('table-footer',{attrs:{"pagination-updated":_vm.getHometasks,"pagination":_vm.pagination}})],1)],1)],1),_c('confirm-modal',{ref:"deleteConfirm",attrs:{"yes":'Удалить',"color":"danger","text":"Вы уверены, что хотите удалить данное задание?"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }