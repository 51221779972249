<template>
  <b-card class="card card-congratulations cursor-pointer">
    <div class="d-flex align-items-center">
      <div>
        <b-card-title class="text-white">
          <skeletor
            width="200px"
            height="20px"
          />
        </b-card-title>

        <div class="d-flex flex-column">
          <skeletor
            width="200px"
            height="14px"
            class="mb-50"
          />

          <skeletor
            width="170px"
            height="14px"
          />
        </div>
      </div>

      <div class="flex-fill" />

      <skeletor
        width="47px"
        height="47px"
      />
    </div>
  </b-card>
</template>

<script>
import { BCard, BCardTitle } from 'bootstrap-vue'

export default {
  name: 'SessionOnlineSkeleton',
  components: {
    BCard, BCardTitle,
  },
}
</script>

<style scoped>

</style>
