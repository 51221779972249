<template>
  <b-card>
    <div>
      <b-button
        v-if="false"
        variant="flat-primary"
      >
        <feather-icon
          class="mr-50"
          icon="EditIcon"
        />
        Редактировать
      </b-button>
    </div>

    <div class="mt-1">
      <b-button
        size="sm"
        variant="outline-secondary"
        @click="$emit('hide')"
      >
        Свернуть
      </b-button>
    </div>
  </b-card>
</template>

<script>
import { BCard, BButton } from 'bootstrap-vue'

export default {
  name: 'HometaskInfo',
  components: {
    BCard,
    BButton,
  },
  props: {
    task: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>

</style>
