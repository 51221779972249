<template>
  <div>
    <active-hometasks-list :student="!isTeacher" />
    <b-row>
      <b-col>
        <div class="mt-2 mb-1 d-flex align-items-center">
          <h2>
            Неактивные занятия
          </h2>
          <div class="flex-fill" />
          <b-button
            variant="primary"
            @click="createHometask"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            Создать
          </b-button>
        </div>
        <b-card
          no-body
          class="mb-0"
        >
          <table-header
            search
            :filter="filter"
            :search-updated="getHometasks"
            :students="students"
            :statuses="statuses"
            statuses-dropdown
          />
          <b-table
            ref="teachersTable"
            striped
            :items="hometasks"
            responsive
            :fields="tableFields"
            primary-key="id"
            show-empty
            :empty-text="$t('page.students.search.labels.no-items')"
            :sort-by.sync="filter.sortBy"
            :sort-desc.sync="filter.sortDesc"
            hover
            @row-clicked="showMore"
          >
            <template #cell(student_name)="{item, value}">
              <div class="d-flex align-items-center">
                <store-image
                  v-if="item.student"
                  class="mr-50"
                  :store-id="item.student.image_id"
                  avatar
                  size="20"
                />
                <span>{{ value }}</span>
              </div>
            </template>
            <template #cell(status)="{item}">
              <b-badge
                pill
                :variant="statusColor(item.status)"
              >
                <span>{{ statusLabel(item.status) }}</span>
              </b-badge>
            </template>
            <template #cell(actions)="{item}">
              <row-actions
                :editable="isTeacher"
                :deletable="isTeacher"
                @edit="$router.push({name: 'cabinet.hometasks.task', params: {task_id: item.id}})"
                @delete="deleteHometask(item)"
              >
                <template #quickAction>
                  <b-button
                    variant="flat-primary"
                    class="btn-icon ml-50 rounded-circle"
                    size="sm"
                    :to="{name: 'cabinet.hometasks.task', params: {task_id: item.id}}"
                  >
                    <feather-icon
                      size="15"
                      :icon="isTeacher ? 'Edit3Icon' : 'ExternalLinkIcon'"
                    />
                  </b-button>
                </template>
                <template #custom>
                  <b-dropdown-item @click="$router.push({name: 'cabinet.hometasks.task', params: {task_id: item.id}})">
                    <feather-icon
                      class="mr-50"
                      icon="ExternalLinkIcon"
                    />
                    <span>Открыть</span>
                  </b-dropdown-item>
                  <b-dropdown-divider v-if="isTeacher" />
                  <b-dropdown-item
                    v-if="isTeacher && item.status === doneStatus"
                    @click="updateTaskStatus(item, activeStatus)"
                  >
                    <feather-icon
                      class="text-warning mr-50"
                      icon="CheckIcon"
                    />
                    <span class="text-warning">{{ activeStatusLabel }}</span>
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-else-if="isTeacher"
                    @click="updateTaskStatus(item, doneStatus)"
                  >
                    <feather-icon
                      class="text-success mr-50"
                      icon="CheckIcon"
                    />
                    <span class="text-success">{{ doneStatusLabel }}</span>
                  </b-dropdown-item>
                </template>
              </row-actions>
            </template>
            <template #row-details="{ toggleDetails, item }">
              <hometask-info
                :task="item"
                @hide="toggleDetails"
              />
            </template>
          </b-table>
          <table-footer
            :pagination-updated="getHometasks"
            :pagination="pagination"
          />
        </b-card>
      </b-col>
    </b-row>

    <confirm-modal
      ref="deleteConfirm"
      :yes="'Удалить'"
      color="danger"
      :text="`Вы уверены, что хотите удалить данное задание?`"
    />
  </div>
</template>

<script>
import {
  BCard, BTable, BCol, BRow, BBadge, BDropdownItem, BButton, BDropdownDivider,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import TableFooter from '@/components/page-elements/table/TableFooter.vue'
import TableHeader from '@/components/page-elements/table/TableHeader.vue'
import { teacherRole, userRoles } from '../../store/user'
import ConfirmModal from '@/components/page-elements/modals/ConfirmModal.vue'
import { getUserFullName } from '@/helpers'
import HometaskInfo from '@/components/hometasks/HometaskInfo.vue'
import StoreImage from '@/components/store/StoreImage.vue'
import RowActions from '@/components/page-elements/table/RowActions.vue'
import ActiveHometasksList from '../../components/hometasks/ActiveHometasksList.vue'
import {
  HOMETASK_STATUS_ACTIVE,
  HOMETASK_STATUS_ACTIVE_LABEL,
  HOMETASK_STATUS_ASSIGNED,
  HOMETASK_STATUS_ASSIGNED_LABEL,
  HOMETASK_STATUS_CHECK, HOMETASK_STATUS_CHECK_LABEL, HOMETASK_STATUS_DONE, HOMETASK_STATUS_DONE_LABEL,
  HOMETASK_STATUS_INACTIVE,
  HOMETASK_STATUS_INACTIVE_LABEL,
} from '../../shared/constants'

export default {
  name: 'Students',
  components: {
    ActiveHometasksList,
    RowActions,
    StoreImage,
    HometaskInfo,
    ConfirmModal,
    TableHeader,
    TableFooter,
    BCard,
    BTable,
    BCol,
    BRow,
    BBadge,
    BDropdownItem,
    BButton,
    BDropdownDivider,
  },
  data: () => ({
    hometasks: [],
    pagination: {
      perPage: 15,
      perPageOptions: [5, 15, 30],
      totalCount: null,
      currentPage: 1,
    },
    filter: {
      search: '',
      sortBy: 'order',
      sortDesc: false,
      student_id: null,
      statuses: null,
    },
    students: [],
    statuses: [
      {
        value: HOMETASK_STATUS_INACTIVE,
        label: HOMETASK_STATUS_INACTIVE_LABEL,
      },
      {
        value: HOMETASK_STATUS_ASSIGNED,
        label: HOMETASK_STATUS_ASSIGNED_LABEL,
      },
      {
        value: HOMETASK_STATUS_ACTIVE,
        label: HOMETASK_STATUS_ACTIVE_LABEL,
      },
      {
        value: HOMETASK_STATUS_CHECK,
        label: HOMETASK_STATUS_CHECK_LABEL,
      },
      {
        value: HOMETASK_STATUS_DONE,
        label: HOMETASK_STATUS_DONE_LABEL,
      },
    ],
  }),
  computed: {
    ...mapGetters({
      user: 'user/get_user',
    }),
    userRole() {
      return this.user ? userRoles[this.user.role] : ''
    },
    isTeacher() {
      return this.userRole === teacherRole
    },
    sortBy() {
      if (!this.filter.sortBy) return null
      let { sortBy } = this.filter
      if (this.filter.sortDesc) sortBy = `-${sortBy}`
      else sortBy = `+${sortBy}`
      return sortBy
    },
    tableFields() {
      return [
        {
          key: 'order',
          label: this.$t('page.hometasks.table.labels.id'),
          sortable: true,
        },
        {
          key: 'name',
          label: this.$t('page.hometasks.table.labels.name'),
          sortable: false,
        },
        // {
        //   key: 'created',
        //   label: this.$t('page.hometasks.table.labels.created_at'),
        //   sortable: true,
        //   formatter: (value, key, task) => (task.created ? this.$options.filters.dateTime(task.created) : this.$t('no_data')),
        // },
        {
          key: 'student_name',
          label: this.$t('page.hometasks.table.labels.student_name'),
          sortable: false,
          formatter: (value, key, task) => getUserFullName(task.student),
        },
        {
          key: 'status',
          label: this.$t('page.hometasks.table.labels.status'),
          sortable: true,
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
        },
      ]
    },
    doneStatus() {
      return HOMETASK_STATUS_DONE
    },
    activeStatus() {
      return HOMETASK_STATUS_ACTIVE
    },
    doneStatusLabel() {
      return HOMETASK_STATUS_DONE_LABEL
    },
    activeStatusLabel() {
      return HOMETASK_STATUS_ACTIVE_LABEL
    },
  },
  watch: {
    sortBy() {
      this.getHometasks()
    },
  },
  async created() {
    await this.getHometasks()
    await this.getStudents()
  },
  methods: {
    async getHometasks() {
      const params = {
        sort: this.sortBy,
        page: this.pagination.currentPage,
        limit: this.pagination.perPage,
        name: this.filter.search,
        status: this.filter.statuses,
        expand: 'student.usersStudent,lessonSession.lessonSessionLessonsHistories.lesson,homeTaskExercises',
      }

      if (this.isTeacher) {
        params.teacher_id = this.user.id
        params.student_id = this.filter.student_id
      } else {
        params.student_id = this.user.id
      }

      const { items, _meta } = await this.$http.get('/home-tasks/index', {
        params,
      })
      this.pagination.totalCount = _meta.totalCount
      this.hometasks = items
    },
    deleteHometask(task) {
      this.$refs.deleteConfirm.open()
        .then(async promise => {
          if (promise.result) {
            this.$http.delete(`/home-tasks/delete?id=${task.id}`)
              .then(() => {
                this.getHometasks()
              })
          }
        })
    },
    async getStudents() {
      const { items } = await this.$http.get('/user/current-teacher-student-index', {
        params: { expand: 'usersStudent' },
      })
      this.students = items
    },
    async updateTaskStatus(task, status) {
      await this.$http.put(`/home-tasks/update?id=${task.id}`, { status })
      this.hometasks.map(_task => {
        const tsk = _task
        if (tsk.id === task.id) tsk.status = status
        return tsk
      })
    },
    async createHometask() {
      await this.$router.push({ name: 'cabinet.hometasks.add' })
    },
    statusColor(status) {
      switch (status) {
        case HOMETASK_STATUS_INACTIVE:
          return 'secondary'
        case HOMETASK_STATUS_ASSIGNED:
          return 'info'
        case HOMETASK_STATUS_ACTIVE:
          return 'warning'
        case HOMETASK_STATUS_CHECK:
          return 'primary'
        case HOMETASK_STATUS_DONE:
          return 'success'
        default:
          return 'secondary'
      }
    },
    statusLabel(status) {
      return this.statuses.find(stat => status === stat.value)?.label
    },

    showMore(record) {
      this.$router.push({ name: 'cabinet.hometasks.task', params: { task_id: record.id } })
    },
  },
}
</script>

<style scoped>

</style>
